'use client';
import Image from 'next/image';
import Link from 'next/link';
import { useState, useEffect } from 'react';

const ToggleWhatsApp = () => {
    const [showWhatsApp, setShowWhatsApp] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const documentHeight = document.documentElement.scrollHeight;
            const windowHeight = window.innerHeight;

            if (scrollPosition + windowHeight >= documentHeight - 800) {
                setShowWhatsApp(true);
            } else {
                setShowWhatsApp(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <>
            {showWhatsApp && (
                <Link
                    href="https://wa.me/34655651561"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Contáctanos por WhatsApp para más información sobre nuestros servicios"
                    title="Contacto por WhatsApp"
                    style={{
                        marginBottom: '45px',
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        backgroundColor: '#25D366',
                        borderRadius: '50%',
                        width: '60px',
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
                    }}
                >
                    <Image
                        src="/images/icons/whatsapp.png"
                        alt="Icono de WhatsApp para contactar"
                        width="40"
                        height="40"
                        loading="lazy"
                        style={{ width: '44px', height: '44px' }}
                    />
                </Link>
            )}
        </>
    );
};

export default ToggleWhatsApp;
